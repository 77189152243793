<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Notifications</div>
                    <div class="card-body">
                        <div aria-atomic="true" aria-live="assertive" class="toast fade show" role="alert">
                            <div class="toast-header">
                                <span class="mr-auto">Notification</span>
                                <small>11 mins ago</small>
                            </div>
                            <div class="toast-body">
                                With supporting text below as a natural lead-in to additional content.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Stacked Notifications</div>
                    <div class="card-body">
                        <div aria-atomic="true" aria-live="assertive" class="toast fade show" role="alert">
                            <div class="toast-header">
                                <span class="mr-auto">Notification</span>
                                <small class="text-muted">Just now</small>
                            </div>
                            <div class="toast-body">
                                With supporting text below as a natural lead-in to additional content.
                            </div>
                        </div>
                        <div aria-atomic="true" aria-live="assertive" class="toast fade show" role="alert">
                            <div class="toast-header">
                                <span class="mr-auto">Notification</span>
                                <small class="text-muted">2 sec ago</small>
                            </div>
                            <div class="toast-body">
                                With supporting text below as a natural lead-in to additional content.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Notifications',
    data() {
        return {
            count: 0
        }
    },
    methods: {
        makeToast(variant = null, toaster = 'b-toaster-top-right', append = false) {
            this.count++
            this.$bvToast.toast(`This is toast number ${this.count}`, {
                title: 'E-Courier Toast',
                variant: variant,
                toaster: toaster,
                autoHideDelay: 5000,
                appendToast: append
            })
        }
    }
}
</script>
